import React from 'react';
import './pages.scss';

const DataProtection = () => {
  return (
    <div className="content-page container flex-grow-1 mt-5">
      <div className="row">
        <h1>Datenschutz</h1>
        <hr className="mb-5" />

        <p className="mb-4">
          Verantwortlicher im Sinne der Datenschutz-Grundverordnung und anderer
          nationaler Datenschutzgesetze der <br />
          Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen
          ist:
        </p>
        <p>HÜGLI NAHRUNGSMITTEL GMBH, mit Sitz in 78315 Radolfzell</p>
        <p>
          <b>Telefon:</b> +49 (0)7732 / 807-1
        </p>
        <p>
          <b>Fax:</b> +49 (0)7732 / 807-201
        </p>
        <p className="mb-4">
          <b>E-Mail-Adresse:</b> info@natuco.bio
        </p>
        <p className="mb-4">
          Der/Die Datenschutzbeauftragte des Verantwortlichen ist:
        </p>
        <p>Herr Markus Kluge</p>
        <p>Güttinger Strasse 23</p>
        <p>78315 Radolfzell</p>
        <p>
          <b>E-Mail-Adresse:</b> datenschutz@natur-compagnie.de
        </p>
        <hr className="my-5" />
        <h4>1. Umfang der Verarbeitung personenbezogener Daten</h4>
        <p>
          Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich
          nur, soweit dies zur Bereitstellung einer funktionsfähigen Website
          sowie unserer Inhalte und Leistungen erforderlich ist und nur bei
          Vorliegen einer der in der Datenschutz-Grundverordnung („DS-GVO“)
          genannten Rechtsgrundlagen.
        </p>
        <h4>2. Beschreibung der Datenverarbeitung</h4>
        <p>
          Bei jedem Aufruf unserer Internetseite erfasst unser System
          automatisiert Daten und Informationen vom Computersystem des
          aufrufenden Rechners. Folgende Daten werden hierbei erhoben:
        </p>
        <p>
          Informationen über den Browsertyp (und dessen Version), das
          Betriebssystem, den Internetserviceprovider, die IP-Adresse, Datum und
          Uhrzeit des Zugriffs, Internetseiten, von denen das System des Nutzers
          auf unsere Internetseite gelangt, Internetseiten, die vom System des
          Nutzers über unsere Internetseite aufgerufen werden, Clickstreams;
        </p>
        <p>
          Die Daten werden ebenfalls in den Logfiles unseres Systems
          gespeichert. Nicht hiervon betroffen sind die IP-Adressen des Nutzers
          oder andere Daten, die die Zuordnung der Daten zu einem Nutzer
          ermöglichen. Eine Speicherung dieser Daten zusammen mit anderen
          personenbezogenen Daten des Nutzers findet nicht statt.
        </p>
        <h4>3. Zweck der Datenverarbeitung</h4>
        <p>
          Die vorübergehende Speicherung der IP-Adresse durch das System ist
          notwendig, um eine Auslieferung der Website an den Rechner des Nutzers
          zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer
          der Sitzung gespeichert bleiben. Die Speicherung in Logfiles erfolgt,
          um die Funktionsfähigkeit der Website sicherzustellen. Zudem dienen
          uns die Daten zur Optimierung der Website und zur Sicherstellung der
          Sicherheit unserer informationstechnischen Systeme. Eine Auswertung
          der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht
          statt.
        </p>
        <h4>4. Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h4>
        <p>
          Zum Zwecke dieses Internetauftrittes gründet sich unsere
          Datenverarbeitung auf folgende Rechtsgrundlagen: Rechtsgrundlage für
          die vorübergehende Speicherung der Daten und der Logfiles ist Art. 6
          Abs. 1 lit. f DS-GVO, da die Speicherung zur Wahrung eines
          berechtigten Interesses unseres Unternehmens oder eines Dritten
          erforderlich ist und die Interessen, Grundrechte und Grundfreiheiten
          des Betroffenen das erstgenannte Interesse nicht überwiegen.
        </p>
        <h4>5. Dauer der Speicherung</h4>
        <p>
          Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
          ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung
          der Daten zur Bereitstellung der Website ist dies der Fall, wenn die
          jeweilige Sitzung beendet ist. Im Falle der Speicherung der Daten in
          Logfiles ist dies nach spätestens 30 Tagen der Fall. Eine
          darüberhinausgehende Speicherung ist möglich. In diesem Fall werden
          die IP-Adressen der Nutzer gelöscht oder verfremdet, sodass eine
          Zuordnung des aufrufenden Clients nicht mehr möglich ist.
        </p>
        <h4>6. Widerspruchs- und Beseitigungsmöglichkeit</h4>
        <p>
          Die Erfassung der Daten zur Bereitstellung der Website und die
          Speicherung der Daten in Logfiles ist für den Betrieb der
          Internetseite zwingend erforderlich. Es besteht folglich seitens des
          Nutzers keine Widerspruchsmöglichkeit. Will der Nutzer die künftige
          und weitergehende Speicherung verhindern, muss er die Internetseite
          verlassen und künftig auf einen Aufruf dieser Internetseite
          verzichten.
        </p>
        <h4>7. Verwendung von Cookies</h4>
        <p>
          Unsere Internetseite verwendet Cookies. Bei Cookies handelt es sich um
          Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem
          Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer eine
          Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers
          gespeichert werden. Dieser Cookie enthält eine charakteristische
          Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim
          erneuten Aufrufen der Website ermöglicht.
        </p>
        <h4>8. Kontaktformular, Telefon- und E-Mail-Kontakt</h4>
        <p>
          Eine Kontaktaufnahme ist über die bereitgestellte E-Mail-Adresse oder
          telefonisch möglich. In diesem Fall werden die mit der E-Mail oder
          telefonisch übermittelten personenbezogenen Daten des Nutzers
          gespeichert, insbesondere Email-Adresse bzw. Telefonnummer.
        </p>
        <p>
          Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an
          Dritte, mit Ausnahme im Rahmen der Bell Food Group. Die Weiterleitung
          erfolgt zum Zwecke der Speicherung in zentralen Datenzentren der Bell
          Food Group und zur Erstellung von Analysen und Strategien für die
          unternehmerische Tätigkeit der Bell Food Group. Dabei kann eine
          Übertragung in ein anderes Land der EU oder des EWR sowie in die
          Schweiz erfolgen.
        </p>
        <p>
          Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer
          Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DS-GVO.
        </p>
        <p>
          Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer
          Übersendung einer E-Mail übermittelt werden, ist Art. 6 Abs. 1 lit. f
          DS-GVO. Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages ab,
          so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1
          lit. b DS-GVO.
        </p>
        <p>
          Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske
          dient uns allein zur Bearbeitung der Kontaktaufnahme. Im Falle einer
          Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche
          berechtigte Interesse an der Verarbeitung der Daten.
        </p>
        <p>
          Die sonstigen während des Absendevorgangs verarbeiteten
          personenbezogenen Daten dienen dazu, einen Missbrauch des
          Kontaktformulars zu verhindern und die Sicherheit unserer
          informationstechnischen Systeme sicherzustellen.
        </p>
        <p>
          Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
          ihrer Erhebung nicht mehr erforderlich sind. Für die personenbezogenen
          Daten aus der Eingabemaske des Kontaktformulars und diejenigen, die
          per E-Mail übersandt wurden, ist dies dann der Fall, wenn die
          jeweilige Konversation mit dem Nutzer beendet ist. Beendet ist die
          Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass
          der betroffene Sachverhalt abschließend geklärt ist.
        </p>
        <p>
          Die während des Absendevorgangs zusätzlich erhobenen personenbezogenen
          Daten werden spätestens nach einer Frist von sieben Tagen gelöscht.
        </p>
        <p>
          Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur
          Verarbeitung der personenbezogenen Daten zu widerrufen. Nimmt der
          Nutzer per E-Mail Kontakt mit uns auf, so kann er der Speicherung
          seiner personenbezogenen Daten jederzeit durch Email an
          datenschutz@huegli.de widersprechen. In einem solchen Fall kann die
          Konversation nicht fortgeführt werden. Ist aufgrund der bereits
          erfolgten Kommunikation eine Handlung erfolgt, die für uns oder den
          Betroffenen rechtsverbindliche Folgen auslöst, sind wir berechtigt,
          ein Löschungsbegehren abzulehnen, sofern wir die Datenverarbeitung auf
          einer der in Artikel 6 DS-GVO genannten Rechtsgrundlagen stützen
          können.
        </p>
        <p>
          Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme
          gespeichert wurden, werden in diesem Fall gelöscht.
        </p>
      </div>
    </div>
  );
};

export default DataProtection;
