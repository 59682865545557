import React from 'react';
import './pages.scss';

const Imprint = () => {
  return (
    <div className="content-page container flex-grow-1">
      <div className="row my-5">
        <h1>Impressum</h1>
        <hr />
        <h4>Firma/Anschrift</h4>
        <p>Hügli Nahrungsmittel GmbH</p>
        <br />
        <p>Güttinger Strasse 23</p>
        <br />
        <p>78315 Radolfzell</p>
        <br />
        <br />
        <p>
          <span className="fw-bold">Tel.:</span>
          <a href="tel:+4977328070"> +49 (0) 7732 / 807-0</a>
        </p>
        <br />
        <p>
          <span className="fw-bold">Fax:</span> +49 (0) 7732 / 807-200
        </p>
        <br />
        <p>
          <span className="fw-bold">E-Mail-Adresse:</span>
          <a href="mailto:huegli@huegli.de"> huegli@huegli.de</a>
        </p>
        <br />
        <br />
        <p>
          <span className="fw-bold">Geschäftsleitung:</span> Dirk Balzer, Jörg
          Meyer und Andreas Rippstein
        </p>
        <br />
        <p>
          <span className="fw-bold">Registergericht und HRB-Nr.:</span> HRB 550
          Freiburg i.Br., HRB 550023
        </p>
        <br />
        <p>
          <span className="fw-bold">Umsatzsteuer-ID-Nr.:</span> DE 142767298
        </p>

        <br />
      </div>
      <div className="row my-5">
        <h1 className="mb-4">Haftungsausschluss</h1>
        <hr />
        <h4>1. Inhalt des Onlineangebots</h4>
        <p>
          Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit,
          Vollständigkeit oder Qualität der bereitgestellten Informationen.
          Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller
          oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung
          der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und
          unvollständiger Informationen verursacht wurden, sind grundsätzlich
          ausgeschlossen, sofern seitens des Autors kein nachweislich
          vorsätzliches oder grob fahrlässiges Verschulden vorliegt. Alle
          Angebote sind freibleibend und unverbindlich. Der Autor behält es sich
          ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne
          gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
          Veröffentlichung zeitweise oder endgültig einzustellen.
        </p>
        <h4>2. Verweise und Links</h4>
        <p>
          Bei direkten oder indirekten Verweisen auf fremde Webseiten
          ("Hyperlinks"), die außerhalb des Verantwortungsbereiches des Autors
          liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in
          Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es
          ihm technisch möglich und zumutbar wäre, die Nutzung im Falle
          rechtswidriger Inhalte zu verhindern. Der Autor erklärt hiermit
          ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen
          Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die
          aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft
          der verlinkten/verknüpften Seiten hat der Autor keinerlei Einfluss.
          Deshalb distanziert er sich hiermit ausdrücklich von allen Inhalten
          aller verlinkten /verknüpften Seiten, die nach der Linksetzung
          verändert wurden. Diese Feststellung gilt für alle innerhalb des
          eigenen Internetangebotes gesetzten Links und Verweise sowie für
          Fremdeinträge in vom Autor eingerichteten Gästebüchern,
          Diskussionsforen, Linkverzeichnissen, Mailinglisten und in allen
          anderen Formen von Datenbanken, auf deren Inhalt externe
          Schreibzugriffe möglich sind. Für illegale, fehlerhafte oder
          unvollständige Inhalte und insbesondere für Schäden, die aus der
          Nutzung oder Nichtnutzung solcherart dargebotener Informationen
          entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen
          wurde, nicht derjenige, der über Links auf die jeweilige
          Veröffentlichung lediglich verweist.
        </p>
        <h4>3. Urheber- und Kennzeichenrecht</h4>
        <p>
          Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der
          verwendeten Bilder, Grafiken, Tondokumente, Videosequenzen und Texte
          zu beachten, von ihm selbst erstellte Bilder, Grafiken, Tondokumente,
          Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken,
          Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle innerhalb
          des Internetangebotes genannten und ggf. durch Dritte geschützten
          Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen
          des jeweils gültigen Kennzeichenrechts und den Besitzrechten der
          jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen
          Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht
          durch Rechte Dritter geschützt sind! Das Copyright für
          veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim
          Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher
          Grafiken, Tondokumente, Videosequenzen und Texte in anderen
          elektronischen oder gedruckten Publikationen ist ohne ausdrückliche
          Zustimmung des Autors nicht gestattet.
        </p>
        <h4>4. Rechtswirksamkeit dieses Haftungsausschlusses</h4>
        <p>
          Dieser Haftungsausschluss ist als Teil des Internetangebotes zu
          betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile
          oder einzelne Formulierungen dieses Textes der geltenden Rechtslage
          nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben
          die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit
          davon unberührt.
        </p>
      </div>
    </div>
  );
};

export default Imprint;
